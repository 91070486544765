import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import './styles/base.css';
import { router } from './plugins/router';
import { CLayout } from './components/Layout';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CLayout>
      <RouterProvider router={router} />
    </CLayout>
  </React.StrictMode>
);
