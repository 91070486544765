import React from 'react'
import { Avatar } from '@mui/joy'

import '../../styles/main.scss'

export const CLayout = ({children}: {children: any}): React.ReactElement => {
    return (
        <>
            <section id={'layout'} className={'flex'}>
                <aside className={'h-screen sticky top-0 z-[50]'}>
                    <div id={'sidebar'} className={'h-full fixed w-[20rem] p-6'}>
                        <h2 className={'text-xl font-medium text-default-900'}>
                            CRM - EEVEN
                        </h2>
                    </div>
                </aside>

                <div className={'flex flex-col flex-1 ml-[20rem]'}>
                    <nav className={'p-3 sticky flex justify-end'}>
                        <Avatar />
                    </nav>
                </div>
            </section>

            {children}
        </>
    )
}
