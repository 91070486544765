import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const VLogin = (): React.ReactElement => {
    const { t } = useTranslation()

    useEffect(() => {
        document.title = t('pages.login.title')
    }, [t])

    return (
        <>
            
        </>
    )
}
